const referenceRenderer = (references) => {
  let richTextImages = {}
  if (references !== undefined) {
    references.map(
      (reference) =>
        (richTextImages[reference.contentful_id] = {
          image: reference.gatsbyImageData,
          alt: reference.title,
        })
    )
  }

  return richTextImages
}

export default referenceRenderer
