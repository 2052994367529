export const formatData = (nodes) => {
  const formattedData = []
  let i = 0
  while (i < nodes.length) {
    if (nodes[i].type === 'h2') {
      const buffer = [nodes[i]]
      i++
      while (i < nodes.length && nodes[i].type !== 'h2') {
        buffer.push(nodes[i])
        i++
      }
      formattedData.push(buffer)
    }
  }

  return formattedData
}
