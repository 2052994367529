import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import useFormattedData from '../../../hooks/useFormattedData'
import '../solutions.scss'

const Tech = (props) => {
  const data = useStaticQuery(graphql`
    query TechQuery {
      allContentfulSolution {
        nodes {
          id
          content {
            raw
          }
          contentfulid
        }
      }
    }
  `)
  const techData = data.allContentfulSolution.nodes.filter(
    (solution) => solution.contentfulid === 'Tech'
  )

  const [index, activePage, getPrevious, getNext, length] = useFormattedData(
    techData[0].content
  )

  return (
    <div>
      <div className="leftSolution">
        <div className="solutionContent">{activePage}</div>
        <div className="solutionButtonContainers">
          <button
            disabled={index === 0}
            className={index === 0 ? 'disabled arrowButton' : 'arrowButton'}
            onClick={() => getPrevious(index)}
          >
            ← Previous
          </button>
          <button
            disabled={index === length - 1}
            className={
              index === length - 1 ? 'disabled arrowButton' : 'arrowButton'
            }
            onClick={() => getNext(index)}
          >
            Next →
          </button>
        </div>
      </div>
      <div className="rightSolutionPanel" />
    </div>
  )
}

export default Tech
