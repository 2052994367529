import { useState, useEffect } from 'react'
import { formatData } from '../utils/formatData'
import richTextRenderer from '../utils/richTextRenderer'
import referenceRenderer from '../utils/referenceRenderer'

export default function useFormattedData(data) {
  const [activePage, setActivePage] = useState(null)
  const [index, setIndex] = useState(0)
  const [arr, setArr] = useState([])

  useEffect(() => {
    const { raw, references } = data
    const assets = referenceRenderer(references)
    const {
      props: { children: nodes },
    } = richTextRenderer(JSON.parse(raw), assets)
    const arrayOfNodes = formatData(nodes)
    setActivePage(arrayOfNodes[index])
    setArr(arrayOfNodes)
  }, [data])

  function getNext(i) {
    if (i < arr.length - 1) {
      setIndex(i + 1)
      setActivePage(arr[i + 1])
    }
  }

  function getPrevious(i) {
    if (i > 0) {
      setIndex(i - 1)
      setActivePage(arr[i - 1])
    }
  }

  return [index, activePage, getPrevious, getNext, arr.length]
}
